import Axios, { AxiosInstance } from 'axios';
import { merge } from 'lodash-es';
import { IRequestConfig, IResponseConfig, IResponse } from './request.type';
import {
  requestReject,
  requestResolve,
  responseReject,
  responseResolve,
} from './interceptors';
import { createCanceler } from './cancel-request';

export default class ApiService {
  private instance: AxiosInstance;

  constructor(requestConfig?: IRequestConfig) {
    const param: Record<string, any> = {
      timeout: 95 * 1000,
      baseURL: 'https://gw.letscubo.com',
      // baseURL: 'https://app2.shenzhuzhuli.com',
      headers: {
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        common: {},
      },
    };

    const instance = Axios.create(merge(param, requestConfig));
    instance.interceptors.request.use(requestResolve, requestReject);
    instance.interceptors.response.use(responseResolve, responseReject);
    this.instance = instance;
  }

  private genCancelToken(config, requestType, url) {
    return config && config.needCancel ? { cancelToken: createCanceler(`${requestType}-${url}`), ...config } : config;
  }

  get<T = any>(
    url: string,
    params: any = {},
    config?: IRequestConfig,
  ): Promise<IResponse<T>> {
    return this.instance
      .get(url, { params, ...this.genCancelToken(config, 'get', url) })
      .then((response: IResponseConfig) => response?.data);
  }

  post<T = any>(
    url: string,
    data: any = {},
    config?: IRequestConfig,
  ): Promise<IResponse<T>> {
    return this.doPostAndPatch('post', url, data, this.genCancelToken(config, 'post', url));
  }

  patch<T = any>(url: string, data = {}, config?: IRequestConfig): Promise<IResponse<T>> {
    return this.doPostAndPatch('patch', url, data, this.genCancelToken(config, 'patch', url));
  }

  delete<T = any>(url: string, config: IRequestConfig): Promise<IResponse<T>> {
    return this.instance
      .delete(url, this.genCancelToken(config, 'delete', url))
      .then((response: IResponseConfig) => response?.data);
  }

  private doPostAndPatch(
    method: 'post' | 'patch',
    url: string,
    data = {},
    config?: IRequestConfig,
  ) {
    return this.instance[method](url, data, config)
      .then((response: IResponseConfig) => response?.data);
  }
}
