import qs from 'qs';
import { forIn, pick } from 'lodash-es';
import { IRequestConfig, IResponseConfig } from './request.type';
import { getNonce, joinDataAndMd5, sortKey } from './request-serialize';

const offset = -new Date().getTimezoneOffset() / 60;
const defaultRequestConfig = {
  loading: false,
  warn: true,
  userId: '-1',
  token: '-1',
  appInfo: 'Web/1.2.0.0',
  source: 204,
  nonce: getNonce(),
  zone: `GMT${offset >= 0 ? `+${offset}` : offset}`,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  locale: Intl.DateTimeFormat().resolvedOptions().locale.replaceAll('-', '_'),
};

export function requestResolve(requestConfig: IRequestConfig): IRequestConfig {
  const config: IRequestConfig = {
    ...defaultRequestConfig,
    nonce: getNonce(),
    ...requestConfig,
    ...requestConfig.data,
  };

  let finalConfig: any = {};

  const copyConfig = JSON.parse(JSON.stringify(config));
  const pickedObj = pick(copyConfig, ['appInfo', 'source', 'nonce', 'zone', 'timeZone', 'locale']);
  forIn(pickedObj, (value, key) => {
    finalConfig[key] = value;
  });

  const data = JSON.parse(JSON.stringify(copyConfig.data));
  forIn(data, (value, key) => {
    if (value === null || value === undefined) {
      delete data[key];
    }
  });
  finalConfig = sortKey({ ...data, ...finalConfig });
  finalConfig.sign = joinDataAndMd5(finalConfig, true).md5Str;
  config.data = qs.stringify(finalConfig);

  return config;
}

export function requestReject(error: Error): void {
  console.error(error);
}

export function responseResolve(response: IResponseConfig): IResponseConfig {
  return response;
}

export function responseReject(error: Error): void {
  console.error(error);
}
