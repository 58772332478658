import axios, { Canceler } from 'axios';

const cancelerMap = new Map<string | symbol, Canceler>();

export function createCanceler(key: string | symbol) {
  cancelRequest(key);
  return new axios.CancelToken((cancel) => (cancelerMap.set(key, cancel)));
}

export function cancelRequest(key: string | symbol) {
  const cancel = cancelerMap.get(key);
  if (cancel) {
    cancel();
    cancelerMap.delete(key);
  }
}
