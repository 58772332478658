import md5 from 'md5-js';
import { IRequestConfig } from './request.type';

export function getNonce() {
  return Math.floor(Math.random() * 1000000);
}

export function sortKey(config: IRequestConfig) {
  const sortedKey: IRequestConfig = {};
  let keys = Object.keys(config);
  keys = keys.sort();
  keys.forEach((key: any) => {
    sortedKey[key] = config[key];
  });
  return sortedKey;
}

export function joinDataAndMd5(data: any, isMd5: any) {
  let str = '';
  /* eslint-disable */
  for (const k in data) {
    str += `${k}=${data[k]}`;
    str += '&';
  }
  // 去掉末尾的&
  if (/.*&$/.test(str)) {
    str = str.substring(0, str.length - 1);
  }
  // console.log("🚀 ~ file: request-serialize.ts:27 ~ joinDataAndMd5 ~ str", str);
  return isMd5 ? { str, md5Str: md5(str) } : { str };
}
